* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.Footer {
    display: flex;
    flex: start;
    align-items: center;
    border-top: 3px solid white;
    gap: 10vw;
    padding: 3vw;
    background-color: #11181f;
    padding-bottom: 5vw;
    color: aliceblue;
    position: relative;
    padding-left: 8vw;
}

.Footer h1 {
    font-size: 1.8vw;
    margin-bottom: 1vw;
    color: #21ef00;

}

.Footer h2 {
    font-size: 1.2vw;
}



.education {
    display: block;
}

.ew {
    margin-top: 1vw;
}


.expertise {
    display: block;
}

.expertise p {
    margin-top: 0.5vw;
}

.language p {
    margin-top: 0.5vw;
}

.language {
    margin-bottom: 3vw;
}

.contact {
    display: flex;
    gap: 2vw;
    position: absolute;
    right: 6vw;
    bottom: 4vw;
}


@media screen and (max-width: 896px) {
    .Footer {
        display: block;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 40vw;
    }

    .Footer h1 {
        font-size: 6vw;
        margin-bottom: 4vw;
        margin-top: 5vw;
    }

    .Footer h2 {
        font-size: 5vw;
        margin-bottom: 1vw;
        margin-top: 1vw;
    }

    .Footer h3 {
        font-size: 4vw;
    }

    .ew {
        margin-top: 4vw;
    }

    .Footer p {
        padding-top: 2vw;
    }

    .contact {
        display: flex;
        position: absolute;
        right: 40%;
        bottom: 10vw;
        gap: 5vw;
    }

    .twiter {
        width: 8vw;
        height: 8vw;
    }

    .link {
        width: 8vw;
        height: 8vw;
    }

    .email {
        width: 8vw;
        height: 8vw;
    }
}