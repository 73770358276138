.basic {
    background-color: #0a1724;
    color: #21ef00;
    position: relative;

}

.bw {
    position: absolute;
    right: 0;
    top: -24vw;
    color: #21ef00;
}

@media screen and (max-width: 896px) {
    .basic {
        margin-right: 100vw;
    }
}