* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.Navbar {
    background-color: #0a1724;
    color: #21ef00;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100px;




}

.logopage {
    display: flex;
    align-items: center;
    position: absolute;
    left: 6vw;


}

img {
    widows: 100px;
    height: 100px;
    z-index: 999;
}


nav {
    display: flex;
}

ul {
    list-style: none;
    display: flex;
    gap: 5vw;
    color: #a4b1cd;
    align-items: center;
    margin-left: 10vw;
}

li {
    font-size: 1.3vw;
    transition: .1s;
    z-index: 999;
}

ul li:hover {
    color: #21ef00;
    cursor: pointer;
    margin-bottom: 2px;
}

@media screen and (max-width: 896px) {
    .Navbar {
        width: 100%;
        align-items: center;
        position: relative;
        height: 80px;

    }

    ul {
        margin-right: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li {
        font-size: 3vw;
    }

    .logopage img {
        display: none;
    }

    .logopage h1 {
        display: none;
    }
}