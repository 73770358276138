* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}


.wrapper {
    position: absolute;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    background-color: #0a1724;
    display: block;
    text-align: center;
}

.Home {
    /* position: relative; */
    width: 100%;
    height: 90vh;
    font-family: 'Roboto', sans-serif;
}




.wrapper h1 {
    font-family: poppins;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
    font-size: 5vw;
    font-family: 'Roboto', sans-serif;
}

.wrapper p {
    font-family: poppins;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3vw;
    font-weight: 500;
    line-height: 1.9 vw;
    font-family: 'Roboto', sans-serif;
}

.box div {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: 3px solid white;
}

.box div:nth-child(1) {
    top: 12%;
    left: 42%;
    width: 30px;
    height: 30px;
    animation: animated 8s linear infinite;
}

.box div:nth-child(2) {
    top: 70%;
    left: 50%;
    animation: animated 5s linear infinite;
}

.box div:nth-child(3) {
    top: 17%;
    left: 6%;
    animation: animated 7s linear infinite;
}

.box div:nth-child(4) {
    top: 20%;
    left: 60%;
    animation: animated 5s linear infinite;
}

.box div:nth-child(5) {
    top: 67%;
    left: 10%;
    animation: animated 8s linear infinite;
}

.box div:nth-child(6) {
    top: 75%;
    left: 70%;
    animation: animated 10s linear infinite;
}

.box div:nth-child(7) {
    top: 60%;
    left: 80%;
    width: 30px;
    height: 30px;
    animation: animated 7s linear infinite;
}

.box div:nth-child(8) {
    top: 32%;
    left: 25%;
    animation: animated 13s linear infinite;
}

.box div:nth-child(9) {
    top: 70%;
    left: 25%;
    width: 50px;
    height: 50px;
    animation: animated 6s linear infinite;
}

.box div:nth-child(10) {
    top: 12%;
    left: 90%;
    animation: animated 5s linear infinite;
}

@keyframes animated {
    0% {
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }

    0% {
        transform: scale(1.3) translateY(90px) rotate(360deg);
        opacity: 0;
    }
}



.logoimg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a1724;
    position: absolute;
    top: 70%;
}

.kali {
    display: flex;
    align-items: center;
    color: #21ef00;
}

.kali img {
    width: 200px;
    height: 100px;
}

.logoimg img {
    width: 100px;
    height: 100px;
}

.linux img {
    position: absolute;
    top: 0;
    left: 37%;
    width: 25vw;
    height: 25vw;
    z-index: 0;
}

.linux h1 {
    z-index: 999;
    color: #21ef00;
    margin-top: 12vw;
}

.linux p {
    z-index: 999;
    /* color: #21ef00; */
    margin-top: 13vw;
    width: 45%;
    text-align: center;
    color: aliceblue;
    line-height: 1.9vw;
}












@media screen and (max-width: 896px) {
    .box {
        display: none;
    }

    .Home {
        width: 100%;
        height: 500px;
        position: relative;
    }

    .linux {
        display: block;
        width: 100%;
        text-align: center;
    }

    .linux h1 {
        font-size: 8vw;
        width: 100%;
        position: absolute;
        top: 65vw;
    }

    .linux img {
        width: 300px;
        height: 300px;
        position: absolute;
        left: 13vw;
    }

    .linux p {
        position: absolute;
        top: 100vw;
        font-size: 5vw;
        width: 90%;
        line-height: 6.5vw;
    }
}